import React from "react"
import PropTypes from "prop-types"

const Preamble = ({ text }) => (
  <section className="pb0">
    <div className="container">
      <div className="row">
        <div className="col-md-8 col-md-offset-2 col-sm-12 text-center">
          <h6 className="uppercase fade-half">Featured Work</h6>
          <h3 className="mb4">{text}</h3>
        </div>
      </div>
    </div>
  </section>
)

Preamble.propTypes = {
  text: PropTypes.string,
}

export default Preamble
